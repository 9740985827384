body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    /* background-color: #f7f7f7; */
  }
  
  .loginContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loginBox {
    /* background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    width: 400px;
    padding: 30px;
    border-radius: 10px; */

    width: 400px;
    padding: 30px;
    /* backdrop-filter: blur(16px) saturate(180%); */
    /* -webkit-backdrop-filter: blur(16px) saturate(180%); */
    background-color: rgba(17, 25, 40, 0.5);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
  }
  
  .loginTitle {
    margin-bottom: 0;
    text-align: left;
    font-size: 24px;
    color: #fff;
    margin-top: 0;
  }

  .loginSubTitle {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: left;
    color: #9ca3af!important;
    font-size: 1rem;
  }

  .inputGroupLabel {
    margin-bottom: .5rem !important;
    display: flex;
    box-sizing: border-box;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    text-align: left !important;
    margin-right: auto;
  }

  .input-group-text {
    background-color: #1f2a37;
    border: 1px solid #4b5663;
    color: #fff;
  }

.form-check {
    display: block;
    margin-bottom: .125rem;
    min-height: 1.5rem;
    padding-left: 1.625em;
}

.form-check-input[type=checkbox] {
    border-radius: .25em;
    background-color: #1f2a37;
    border: 1px solid #4b5663;
}

.form-check-label {
    color: white;
    text-align: center;
}

.form-check-input {
    background-color: #1f2a37;
    border: 1px solid #4b5663;
    cursor: pointer;
}

.remember-me {
    display: flex;
    margin-right: auto;
    margin-left: -30px;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 10px;
}
  
  .inputContainer {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  /* .inputContainer label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #999;
    pointer-events: none;
    transition: 0.2s ease;
  } */
  
  /* .inputContainer input:focus~label,
  .inputContainer input:valid~label {
    top: 0;
    font-size: 13px;
    padding: 0 5px;
  } */
  
  .loginInput {
    width: 100%;
    padding: 15px;
    border: 1px solid #4b5663;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    background-color: #1f2a37;
    color: white;
  }
  
  .loginInput:focus,
  .loginInput:hover {
    outline: none;
    border-color: #2ea44f;
  }

  .loginInput:focus {
    background-color: #3d4651;
  }
  
  .loginButton {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 4px;
    background-color: #2ea44f;
    border-color: #2ea44f;
    box-shadow: 0 1px 1px rgba(18, 21, 26, .075);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .loginButton:hover {
    background-color: #2b9749;
  }

  .orLoginWith {
    /* display: flex; */
    /* text-align: center; */
    /* margin-left: auto; */
    /* margin-left: auto; */
    color: white;
    font-size: 13px;
  }

  .googleLoginButton {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 4px;
    background-color: #ffffff;
    border-color: #2ea44f;
    box-shadow: 0 1px 1px rgba(18, 21, 26, .075);
    color: #000000;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .googleLoginButton:hover {
    background-color: #eeeeee;
  }

  .appleLoginButton {
    margin-top: 10px;
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    /* border: 1px solid #000000; */
    box-shadow: 0 1px 1px rgba(18, 21, 26, .075);
    color: #000000;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .appleLoginButton:hover {
    background-color: #eeeeee;
  }

  .appleLoginSpan {
    font-weight: 500;
    margin-left: 5px;
  }

  .appleLoginSVG {
    margin-bottom: -2px;
  }

  .googleLoginSpan {
    font-weight: 500;
    margin-left: 5px;
  }

  .googleLoginSVG {
    margin-bottom: -4px;
  }

  .orLoginWithLine {
    width: 100%;
    background-color: white;
    height: 1px;
    /* margin-top: -20px; */
    margin-top: 15px;
    z-index: 1;
  }

  .orLoginWith {
    /* margin-top: -9.5px; */
    z-index: 2;
    /* margin-bottom: 10px; */
  }

  .rememberInput {
    /* Hide the default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #4CAF50; /* Border color */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Change cursor to pointer */
    margin-top: -1.5px;
  }
  
  .rememberInput:checked {
    background-color: #4CAF50; /* Checked background color */
  }

  .rememberInput:checked::after {
    content: ""; /* Add content to the pseudo-element */
    display: inline-block; /* Display as inline-block */
    width: 6px; /* Width of the default checkmark */
    height: 12px; /* Height of the default checkmark */
    border: solid rgb(0, 0, 0); /* Border color of the default checkmark */
    border-width: 0 2px 2px 0; /* Adjust thickness and angle of the checkmark */
    transform: rotate(45deg); /* Rotate the checkmark to form a check */
    margin-left: 5px; /* Adjust spacing between the checkbox and checkmark */
  }

  .rememberMe {
    display: flex;
    margin-right: auto;
    margin-bottom: 5px;
    /* margin-top: -5px; */
  }

  .rememberMe label {
    color: white;
    font-size: 13px;
    cursor: pointer;
  }

  .doNotHaveAccount {
    color: white;
    text-align: left;
    font-size: 13.2px;
    font-family: 'Open Sans', sans-serif;
  }

  .doNotHaveAccount span {
    color: #2e85ff;
    cursor: pointer;
  }

  .doNotHaveAccount span:hover {
    color: #2877e5;
  }